import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
// components
import Logo from '../components/Logo';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <HeaderStyle>
        <Box display={'flex'} justifyContent={isDesktop ? 'flex-start' : 'center'} alignItems={'center'}>
          <Logo/>
        </Box>
        {!isDesktop &&
          <Divider sx={{height: 2}}/>
        }
      </HeaderStyle>
      <Outlet />
    </>
  );
}
