import axios from 'src/utils/axios';
import { HOST_API } from '../config';

export async function getCheckoutSessionInfo(sessionId)
{
    return await axios.post("/applications/checkout/get_cashe_url",{
        sessionId
    }, {headers:{Accept:'*/*'}})
}


export async function validateUpi(upiAddress)
{
    return await axios.post("/payments/razorpay/validate_upi_address_cashfree",{
        upiAddress
    }, {headers:{Accept:'*/*'}})
}


// export async function validateUpi(upiAddress)
// {
//     return await axios.post("/payments/razorpay/validate_upi_address_idfy",{
//         upiAddress
//     }, {headers:{Accept:'*/*'}})
// }


export async function requestOtp(value, type='mobile')
{
    if (type === 'email') {
        return await axios.post("/account/login/request_otp", {email: value})
    } else if (type === 'mobile') {
        return await axios.post("/account/login/request_otp", {mobile: value})
    }
}

export async function verifyOtp(value, otp, type='mobile')
{
    if (type === 'email') {
        return await axios.post("/account/login/verify_otp", {email: value, otp})
    } else if (type === 'mobile') {
        return await axios.post("/account/login/verify_otp", {mobile: value, otp})
    }
}

export async function updateEmail(mobile, email)
{
    return await axios.get("/account/create/update_email", {mobile, email})
}