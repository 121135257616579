import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string,
};

export default function RHFSwitch({ name, disabled=false, ...other }) {
  const { control } = useFormContext();

  return (
    <>
      {!disabled &&
        <FormControlLabel
          control={
            <Controller name={name} control={control} render={({ field, fieldState: { error } }) => <Switch error={!!error} helperText={error?.message} {...field} checked={field.value} />} />
          }
          {...other}
        />
      }
      {disabled &&
        <FormControlLabel
          control={
            <Controller name={name} control={control} render={({ field, fieldState: { error } }) => <Switch error={!!error} helperText={error?.message} {...field} checked={field.value} disabled/>} />
          }
          {...other}
        />
      }
    </>
  );
}
