import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import * as api from '../../_apis_/service'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  mobile: null,
  checkinDetails: null,
  providerId: null,
  providerDetails: null
};

const slice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    newCheckinSuccess(state, action) {
      state.isLoading = false;
      state.checkinDetails = action.payload;
      state.mobile = action.payload.mobile;
    },

    // GET PROVIDER ID
    getProviderIdSuccess(state, action) {
      state.isLoading = false;
      state.providerId = action.payload;
    },

    // GET PROVIDER DETAILS
    getProviderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.providerDetails = action.payload;
      state.providerId = action.payload.providerId;
    },
    
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

export function checkin(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkin/manage/new', data);
      dispatch(slice.actions.newCheckinSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderDetails(providerId) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/get_provider_details', {providerId});
      dispatch(slice.actions.getProviderDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


