import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(
  (
    {
      children,
      title = '',
      description = 'Better Healthcare. Easier Payments! Enroll into No Cost EMI plans through BrightPay',
      ...other
    },
    ref
  ) => (
    <>
      <Helmet>
        <title>{`${title} | BrightPay`}</title>
        <meta name="title" itemProp="title" content={title} />
        <meta name="description" itemProp="description" content={description} />
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
