import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  changePlan: false,
  error: null,
  prospectInfo: {},
  signedUrl: null,
  billUrl: null,
  applicationInfo: {},
  checkoutSession: {},
  eligiblePlans: {},
  paymentMethods: {},
  availablePaymentMethods: {}
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROSPECT INFO
    getProspectInfoSuccess(state, action) {
      state.isLoading = false;
      state.prospectInfo = action.payload;
    },

    // GET APPLICATION INFO
    getApplicationInfoSuccess(state, action) {
      state.isLoading = false;
      state.applicationInfo = action.payload;
    },

    // CREATE APPLICATION INFO
    createProspectSuccess(state, action) {
      state.isLoading = false;
      state.prospectInfo = action.payload;
    },

    // GET ELIGIBLE PLANS SUCCESS
    getEligiblePlansSuccess(state, action) {
      state.isLoading = false;
      state.eligiblePlans = action.payload;
    },

    // GET ELIGIBLE PLANS SUCCESS
    getAvailablePaymentMethodsByApplicationSuccess(state, action) {
      state.isLoading = false;
      state.availablePaymentMethods = action.payload;
    },

    // GET ALL PLANS SUCCESS
    getAllAvailablePaymentMethodsSuccess(state, action) {
      state.isLoading = false;
      state.paymentMethods = action.payload;
    },

    // UPDATE APPLICATION INFO
    updateApplicationSuccess(state, action) {
      state.isLoading = false;
      state.applicationInfo = action.payload;
    },

    // UPLOAD BILL
    updateSignedUrl(state, action) {
      state.isLoading = false;
      state.signedUrl = action.payload.signedUrl
      state.billUrl = action.payload.billUrl
    },

    // UPLOAD BILL
    uploadBillSuccess(state, action) {
      state.isLoading = false;
    },

    // CREATE CHECKOUT SESSION 
    createCheckoutSessionSuccess(state, action) {
      state.isLoading = false;
      state.applicationInfo = action.payload.applicationInfo;
      state.checkoutSession = action.payload.paymentCheckoutSession;
    },

    // UPDATE CHECKOUT STATUS 
    updateCheckoutChangesSuccess(state, action) {
      state.isLoading = false;
    },

    // CLEAR CHECKOUT SESSION 
    clearCheckoutSession(state) {
      state.isLoading = false;
      state.checkoutSession = {};
    },

    // UPDATE CHECKOUT SESSION STATUS
    updateCheckoutSessionStatus(state, action) {
      console.log('action.payload', action.payload)
      state.isLoading = false;
      state.applicationInfo = action.payload.applicationInfo;
      state.checkoutSession = action.payload.checkoutSession;
    },

    // OPEN MODAL
    changePlanModeOn(state) {
      state.changePlan = true;
    },

    // OPEN MODAL
    changePlanModeOff(state) {
      state.changePlan = false;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { changePlanModeOn, changePlanModeOff, clearCheckoutSession } = slice.actions;

// ----------------------------------------------------------------------

export function getApplicationByPartnerIdAppId(providerId, applicationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/get_details_by_provider_app_ids', {
        providerId,
        applicationId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getApplicationInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplicationByAppId(applicationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/get_details_by_app_id', {
        applicationId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getApplicationInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProspect(prospectId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/get_prospect', {
        prospectId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getProspectInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function moveProspectToApplication(prospectId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/move_prospect_to_applicant', {
        prospectId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getApplicationInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function newProspectFromScan(providerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/new_prospect_from_scan', {
        providerId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.createProspectSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEligiblePaymentPlans(applicationId, mobile, loanAmount) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/get_eligible_payment_plans', {
        'loan_amount': loanAmount,
        mobile,
        applicationId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getEligiblePlansSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllAvailablePaymentMethods() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/applications/manage/get_available_payment_methods_single_list');
      const response = await axios.get('/applications/manage/get_available_payment_methods');
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getAllAvailablePaymentMethodsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAvailablePaymentMethodsByApplication(applicationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/applications/manage/get_available_payment_methods_single_list');
      const response = await axios.post('/applications/manage/get_available_payment_methods_by_application',{
        applicationId
      });
      if (response.data.error) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getAvailablePaymentMethodsByApplicationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateApplication(applicationInfo) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/manage/update_application', applicationInfo);
      dispatch(slice.actions.updateApplicationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSignedUrl() {
  return async () => {
    try {
      const response = await axios.get('/applications/manage/create_new_signed_url_for_bill');
      dispatch(slice.actions.updateSignedUrl(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// var reader = new FileReader();
//       await reader.readAsDataURL(file);
//       console.log('file b64', reader)
//       reader.onload = function(e)
//       {
//           const result = e.target.result;
//           const buffer = Buffer.from(result.replace(/^data:image\/\w+;base64,/, ""),'base64');
//           if (result) {
//             const response = axios.put(signedUrl, buffer, {
//               headers: {
//                 'Content-Type': result.split(';')[0].split(':')[1],
//                 'Content-Encoding': 'base64'
//               }});
//           }
//       };

export function uploadBill(signedUrl, file) {
  return async () => {
    try {
      const response = axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64'
      }});
      dispatch(slice.actions.uploadBillSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCheckoutSession(applicationInfo) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/checkout/create_session', applicationInfo);
      dispatch(slice.actions.createCheckoutSessionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCheckoutChanges(sessionId, status) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/checkout/update_checkout_changes', {
        sessionId,
        status
      });
      dispatch(slice.actions.updateCheckoutChangesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCheckoutSessionStatus(sessionId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/applications/checkout/get_status', {
        sessionId
      });
      dispatch(slice.actions.updateCheckoutSessionStatus(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
